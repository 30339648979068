import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Page from 'components/Page'
import Metadata from 'components/Metadata'

class Profile extends React.Component {
  render() {
    return <div>Test</div>
  }
}

export default Profile
